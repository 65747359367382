import {useCallback, useEffect, useMemo, useState} from 'react';

import {type FormikHelpers, useFormik, FormikConfig} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {CountableMoneyField} from 'components/forms_fields';
import InlineMoneyField from 'components/forms_fields/InlineMoneyField';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {MOBILE_BREAKPOINT_WIDTH} from 'globals/ScreenSizeConstants';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import useWindowSize from 'hooks/useWindowResize';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormValues = {
  rentAmount: number;
  bondAmount?: number | null;
  rentalPeriod: string;
};

const FinancialsStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll('root-scroll-container');

  /**
   * Find the most recent listing for the property.
   */
  const listing = useMostRecentlyCreated(property.listings);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartRentDetailsStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );
  }, [property.id, listing.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);

  const [isMobile, setIsMobile] = useState(
    window.screen.width <= MOBILE_BREAKPOINT_WIDTH,
  );

  const queryClient = useQueryClient();

  const {width: windowWidth} = useWindowSize();

  useEffect(
    () => setIsMobile(windowWidth <= MOBILE_BREAKPOINT_WIDTH),
    [windowWidth],
  );

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      /**
       * Set changes on the property.
       */
      property.lastOnboardingStepCompleted = 'listing_financials';

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Set changes on the listing.
       */
      listing.assignAttributes(formValues);

      /**
       * Save the changes to the listing.
       */
      if (!(await saveResource(listing))) {
        setSubmitting(false);
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.ListProperty_CompleteRentDetailsStep,
        {
          propertyId: property.id,
          listingId: listing.id,
        },
      );

      setSubmitting(false);
      actions.setSubmitting(false);
    },
    [listing, property, queryClient],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormValues>>(
    () => ({
      initialValues: {
        rentAmount: listing.rentAmount || 0,
        bondAmount: listing.bondAmount || null,
        rentalPeriod: 'Weekly',
      } as FormValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        rentAmount: Yup.number().required().min(1).label('Rent'),
        bondAmount: Yup.number()
          .min(0)
          .optional()
          .nullable()
          .label('Bond')
          .test(
            'max-bond-is-allowed',
            'Bond can not be greater than 4 weeks rent',
            function (value) {
              if (!value || value == 0) {
                return true;
              }
              const rentalPeriod = this.parent.rentalPeriod;
              const rent = this.parent.rentAmount;
              if (rentalPeriod === 'Weekly') {
                return value <= rent * 4;
              } else if (rentalPeriod === 'Fortnightly') {
                return value <= rent * 2;
              } else {
                return true;
              }
            },
          ),
      }),
    }),
    [handleSubmit, listing],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    // TODO: Add a subtitle
    <StepContainer
      align="center"
      title="Next up, tenancy information..."
      subtitle="">
      <div className="flex flex-col space-y-8">
        {isMobile ? (
          <>
            <InlineMoneyField
              formik={form}
              name="rentAmount"
              label="Rent per week"
            />

            <InlineMoneyField
              formik={form}
              name="bondAmount"
              label="Bond (optional)"
            />
          </>
        ) : (
          <>
            <CountableMoneyField
              formik={form}
              name="rentAmount"
              labelProps={{
                title: 'Rent per week',
                size: 'xl',
              }}
            />

            <CountableMoneyField
              formik={form}
              name="bondAmount"
              labelProps={{
                title: 'Bond (optional)',
                size: 'xl',
              }}
            />
          </>
        )}
      </div>
    </StepContainer>
  );
};

export default FinancialsStep;
