import {useMemo} from 'react';

import {HiOutlinePlus} from 'react-icons/hi';
import {IoHammerOutline} from 'react-icons/io5';
import {useNavigate} from 'react-router';

import ServiceRequestsTable from 'components/service_request/ServiceRequestsTable';
import {Card} from 'components_sb/layout';
import EmptyContentPlaceholder from 'components_sb/layout/EmptyContentPlaceholder/EmptyContentPlaceholder';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Tenancy from 'models/properties/Tenancy';
import {Action} from 'types/actions';

const MaintenanceRequestsCard = ({tenancy}: {tenancy?: Tenancy}) => {
  const navigate = useNavigate();

  const showServiceRequest = (serviceRequestId: string) => {
    navigate(`service-requests/${serviceRequestId}`);
  };

  const {activeAccountRole} = useLocalUserSettings();

  /**
   * Actions that are applicable to and accessible
   * from within the card.
   */
  const cardActions = useMemo<Action[]>(
    () =>
      !tenancy
        ? null
        : [
            {
              label: 'New maintenance request',
              icon: HiOutlinePlus,
              linkTo:
                activeAccountRole === 'Landlord'
                  ? `tenancies/${tenancy.id}/service-requests/new`
                  : 'service-requests/new',
            },
          ],
    [tenancy, activeAccountRole],
  );

  return (
    <Card
      icon={IoHammerOutline}
      title="Maintenance Requests"
      actions={cardActions}>
      {tenancy && tenancy.serviceRequests.length > 0 ? (
        <ServiceRequestsTable
          requests={tenancy.serviceRequests}
          clickHandler={showServiceRequest}
          accountType={activeAccountRole}
        />
      ) : (
        <EmptyContentPlaceholder
          type="maintenance"
          message="There are currently no maintenance requests for this property"
        />
      )}
    </Card>
  );
};

export default MaintenanceRequestsCard;
