import {useCallback, useEffect, useMemo, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import {HiCheckCircle} from '@react-icons/all-files/hi/HiCheckCircle';
import clsx from 'clsx';
import {intersection} from 'lodash';
import {Collapse} from 'react-collapse';
import {HiOutlineCheck, HiOutlineSparkles, HiOutlineX} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';

import LoadingView from 'components/common/LoadingView';
import AddBillingMethodModal from 'components/payment/AddBillingMethodModal';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {Button} from 'components_sb/buttons';
import {InlineError} from 'components_sb/feedback';
import {GridSelect} from 'components_sb/input';
import {Card, Modal} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import listingPlatforms, {
  PREMIUM_LISTING_FEE_EXCL_GST,
} from 'constants/listing-platforms';
import {OBFS, OnboardingFlow} from 'constants/onboarding-flow-steps';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import BillingMethod from 'models/billing/BillingMethod';
import {ListingStatus} from 'models/listings/Listing';
import Tenancy, {TenancyStatus} from 'models/properties/Tenancy';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import arrayToSentence from 'utilities/array-to-sentence';
import {saveResource} from 'utilities/SpraypaintHelpers';

const {useModal} = Modal.Imperative;

const PublishStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll('root-scroll-container');

  /**
   * Find the most recent listing for the property.
   */
  const listing = useMostRecentlyCreated(property.listings);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartPublishListingStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );
  }, [property.id, listing.id, scrollToTop]);

  /**
   * Fetch any existing billing methods for the user.
   */
  const billingMethodsQuery = useQuery(
    'fetch-user-billing-methods',
    async () => await BillingMethod.select(['nickname', 'metadata']).all(),
    {retry: 1},
  );

  /**
   * Determine if the user has added any billing methods.
   */
  const hasAddedBillingMethod = useMemo(
    () =>
      billingMethodsQuery.isSuccess && billingMethodsQuery.data.data.length > 0,
    [billingMethodsQuery],
  );

  /**
   * Handle adding a new billing method.
   */
  const openModal = useModal();

  const onAddBillingMethod = useCallback(
    () => openModal(AddBillingMethodModal),
    [openModal],
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /**
   * Separete the free platforms and order by those
   * that are currently avalaible first.
   */
  const freePlatforms = useMemo(
    () => listingPlatforms.filter(({premium}) => !premium),
    [],
  );

  /**
   * Separete the premium platforms and order by those
   * that are currently avalaible first.
   */
  const premiumPlatforms = useMemo(
    () => listingPlatforms.filter(({premium}) => !!premium),
    [],
  );

  /**
   * The platforms that the property is to be listed on.
   * (free platforms are selected by default)
   */
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>(
    freePlatforms.filter(({implicit}) => !implicit).map(({site}) => site),
  );

  /**
   * Either removes or adds a platform ID to the array of
   * selected platform IDs.
   */
  const onSelect = useCallback(
    (platformId: string, selected: boolean) =>
      setSelectedPlatforms((current) => {
        if (selected && !current.includes(platformId)) {
          return [...current, platformId];
        } else if (!selected && current.includes(platformId)) {
          return current.filter((id) => id !== platformId);
        }
        return current;
      }),
    [],
  );

  const setStepCompleted = useCallback(async () => {
    /**
     * Update the last onboarding step completed on the property.
     */
    property.noTenancyActionType = OnboardingFlow.NewTenancy;
    property.lastOnboardingStepCompleted = OBFS.NewGeneralInformation;

    /**
     * Save the changes to the property.
     */
    if (!(await saveResource(property))) {
      throw new Error('Failed to save property');
    }
  }, [property]);

  const [submitting, setSubmitting] = useState(false);

  /**
   * Publishes the listing after confirming.
   */
  const publishListing = useCallback(async () => {
    setSubmitting(true);

    if (!listing) {
      // TODO: Handle listing not existing
      return;
    }

    /**
     * Ensure that the listing has not already been published.
     */
    if (listing.status === ListingStatus.PendingApproval) {
      try {
        await setStepCompleted();
      } catch (error) {
        return;
      }
      toast.error('This listing has already been published.');
      navigate(`/listings/${listing.publicId}`);
      return;
    }

    /**
     * Set the platforms to publish the listing to.
     */
    listing.chosenExternalListingSites = selectedPlatforms;

    /**
     * Set the listing to the pending approval state.
     */
    listing.status = ListingStatus.PendingApproval;

    /**
     * Save the changes to the listing.
     */
    if (!(await saveResource(listing))) {
      setSubmitting(false);
      return;
    }

    if (listing.errors?.length) {
      toast.error('There was an error publishing your listing.');
      setSubmitting(false);
      return;
    }

    let tenancy: Tenancy;
    const possibleTenancy = await Tenancy.where({propertyId: property.id})
      .order({createdAt: 'desc'})
      .first();

    if (
      possibleTenancy &&
      possibleTenancy.data &&
      possibleTenancy.data.status === TenancyStatus.Draft
    ) {
      tenancy = possibleTenancy.data;
    } else {
      tenancy = new Tenancy({
        propertyId: property.id,
        status: TenancyStatus.Draft,
      });
    }

    tenancy.isNew = true;

    /**
     * Save the changes to the tenancy.
     */
    if (!(await saveResource(tenancy))) {
      setSubmitting(false);
      return;
    }

    /**
     * Set the step as completed by moving the user to the new tenancy flow.
     */
    try {
      await setStepCompleted();
    } catch (error) {
      setSubmitting(false);
      return;
    }

    /**
     * Update the property data in the query cache.
     */
    queryClient.setQueryData(
      ['property', {id: property.id, context: 'onboarding-flow'}],
      property,
    );

    toast.success(
      'Your listing is awaiting approval and will be published soon!',
    );

    /**
     * Clear out listing from walkthrough.
     */
    localStorage.removeItem('new-property-id');

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_CompletePublishListingStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );

    /**
     * Track completion of the flow.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_CompleteFlow,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );
    navigate(`/listings/${listing.publicId}`);
  }, [
    listing,
    navigate,
    property,
    selectedPlatforms,
    queryClient,
    setStepCompleted,
  ]);

  /**
   * Whether the user has selected any premium platforms.
   */
  const hasSelectedPremiumPlatforms = useMemo(
    () =>
      !!intersection(
        selectedPlatforms,
        premiumPlatforms.map(({site}) => site),
      ).length,
    [selectedPlatforms, premiumPlatforms],
  );

  /**
   * Validate that the user has added a billing method before publishing.
   */
  const confirmPublishListing = useCallback(() => {
    if (hasSelectedPremiumPlatforms && !hasAddedBillingMethod) {
      toast.error(
        'Please add a billing method or remove your selected optional extras to publish your listing.',
      );
      return;
    }
    publishListing();
  }, [hasSelectedPremiumPlatforms, hasAddedBillingMethod, publishListing]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        label: submitting ? 'Publishing listing...' : 'Publish listing',
        loading: submitting,
        icon: HiOutlineSparkles,
        onClick: confirmPublishListing,
      },
    },
  });

  return (
    <StepContainer
      fullWidth
      title="Publish your listing"
      subtitle="By listing with Keyhook, your listing will be published across New Zealand's most popular rental listing websites.">
      {/* Loading screen */}
      {billingMethodsQuery.isLoading && <LoadingView />}
      {/* Error screen */}
      {billingMethodsQuery.isError && (
        <InlineError error="There was an issue with this page, please contact our support team." />
      )}
      {/* Main content screen */}
      {billingMethodsQuery.isSuccess && (
        <>
          <div className="flex flex-col gap-y-2">
            {/* Listing platforms */}
            <div>
              <Title size="sm" level="h2">
                What's included
              </Title>
              <Paragraph>
                The following advertising platforms are included for{' '}
                <strong>free</strong> when listing with Keyhook and will be
                published to automatically.
              </Paragraph>
            </div>
            {/* Base platforms */}
            <Card>
              <div className="flex flex-row flex-wrap justify-center items-center gap-y-4 md:gap-y-10 gap-x-8 md:gap-x-14">
                {freePlatforms.map(({site, label, logo}) => (
                  <div
                    key={site}
                    className="flex justify-center items-center w-20 md:w-36 h-20 relative">
                    <img
                      src={logo}
                      alt={`${label} Logo`}
                      className="max-w-full max-h-full object-contain"
                    />
                  </div>
                ))}
              </div>
            </Card>
            {Capacitor.isNativePlatform() ? (
              <div>
                <Title size="sm" level="h2">
                  Trade Me Property
                </Title>
                <Paragraph>
                  Currently, posting your listing to Trade Me is not available
                  through the mobile app. To list on Trade Me, please log into
                  Keyhook using your desktop web browser.
                </Paragraph>
                <Paragraph secondary>
                  <strong>Note:</strong> You can post your listing to the above
                  platforms now, and opt into Trade Me at a later stage!
                </Paragraph>
              </div>
            ) : (
              <>
                {/* Premium platforms */}
                {premiumPlatforms.length > 0 && (
                  <>
                    <div>
                      <Title size="sm" level="h2">
                        Optional extras
                      </Title>
                      <Paragraph>
                        For an additional fee, you may also choose to list your
                        property on the following:
                      </Paragraph>
                    </div>
                    <Card style="premium">
                      <div className="flex flex-col-reverse sm:flex-row gap-x-12 gap-y-4 items-start sm:items-center">
                        <div className="flex-1 flex flex-col">
                          <div className="flex flex-col flex-wrap justify-center items-start mb-4 gap-y-4 md:gap-y-6 gap-x-8 md:gap-x-14">
                            <div className="flex flex-row gap-x-6">
                              {premiumPlatforms.map(({site, label, logo}) => (
                                <div
                                  key={site}
                                  className={clsx(
                                    'flex-shrink-0 flex flex-row justify-center items-center w-24 md:w-36 h-20 relative',
                                  )}>
                                  <img
                                    src={logo}
                                    alt={`${label} Logo`}
                                    className="max-w-full max-h-full object-contain"
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="flex flex-col gap-y-2">
                              <Paragraph>
                                {`Add ${arrayToSentence(
                                  premiumPlatforms.map(({label}) => label),
                                )} for `}
                                <strong>
                                  ${PREMIUM_LISTING_FEE_EXCL_GST.toFixed(2)}
                                </strong>
                                {` + GST?`}
                              </Paragraph>
                              <div className="max-w-[250px]">
                                <GridSelect
                                  mode="manual:select"
                                  size="base"
                                  disableTicks
                                  multiple={false}
                                  minColumns={2}
                                  maxColumns={2}
                                  options={[
                                    {
                                      id: 'yes',
                                      label: 'Yes',
                                      icon: HiOutlineCheck,
                                    },
                                    {
                                      id: 'no',
                                      label: 'No',
                                      icon: HiOutlineX,
                                    },
                                  ]}
                                  value={
                                    premiumPlatforms.every(({site}) =>
                                      selectedPlatforms.includes(site),
                                    )
                                      ? 'yes'
                                      : 'no'
                                  }
                                  onChange={(value) => {
                                    premiumPlatforms.forEach(({site}) => {
                                      onSelect(site, value === 'yes');
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <Paragraph size="sm" secondary disableMargin>
                            <em>
                              You can also choose to opt in later after
                              initially publishing.
                            </em>
                          </Paragraph>
                        </div>
                        <div
                          className={clsx(
                            'w-full sm:w-auto',
                            'h-full bg-white rounded-xl',
                            'flex flex-col gap-y-2',
                            'justify-start items-start',
                            'text-amber-500',
                            'font-semibold text-lg',
                          )}>
                          <span className="text-lg font-semibold leading-none">
                            Premium Listing
                          </span>
                          <ul
                            className={clsx(
                              'flex flex-col gap-y-1',
                              'list-disc list-inside',
                              'text-sm font-normal',
                              'whitespace-nowrap',
                            )}>
                            {[
                              "Trade Me's biggest ad",
                              '23% more watch-list adds',
                              '35% more listing views',
                              'Top of search results',
                            ].map((benefit) => (
                              <li key={benefit}>{benefit}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Card>
                  </>
                )}
              </>
            )}
            <Collapse isOpened={hasSelectedPremiumPlatforms}>
              <div className="mb-6">
                <Title size="sm" level="h2">
                  Billing method
                </Title>
                {hasAddedBillingMethod ? (
                  <div className="flex flex-row items-center gap-x-2 mb-4">
                    <HiCheckCircle className="text-green-600 w-6 h-6" />
                    <Paragraph disableMargin>
                      You have a credit or debit card saved with Keyhook, we
                      will use the default card for your account.
                    </Paragraph>
                  </div>
                ) : (
                  <div className="flex flex-col gap-y-2 mb-4">
                    <Paragraph>
                      Please add a billing method to continue.
                    </Paragraph>
                    <Button
                      label="Add a new credit/debit card"
                      category="primary"
                      size="base"
                      fillWidth={false}
                      mode="manual"
                      onClick={onAddBillingMethod}
                    />
                  </div>
                )}
              </div>
            </Collapse>
            <div>
              <Title size="sm" level="h2">
                Ready to publish?
              </Title>
              {/* Terms of use reminder */}
              <Paragraph>Great! Just tap the publish button below.</Paragraph>
              <Paragraph secondary>
                Please note that your listing will be subject to a quick review
                by the Keyhook team and will be published once approved. This
                usually takes less than two hours.
              </Paragraph>
            </div>
          </div>
        </>
      )}
    </StepContainer>
  );
};

export default PublishStep;
