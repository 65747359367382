import {useCallback} from 'react';

import {useFormik} from 'formik';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {Button} from 'components_sb/buttons';
import {TextField} from 'components_sb/input';
import {AuthFormLayout} from 'components_sb/layout';
import {Link} from 'components_sb/navigation';
import useAuth from 'services/useAuth';
import SocialAuth, {SocialAuthProviderType} from 'utilities/auth/SocialAuth';
import {usePageVisit, useTitle} from 'utilities/hooks';

const LoginPage = () => {
  useTitle('Login');
  usePageVisit('LoginPage');

  // Load the external scripts required for social authentication
  SocialAuth.useAuthScripts();

  const {userIsLoggedIn, logInUser} = useAuth();

  const navigate = useNavigate();

  if (userIsLoggedIn) {
    const backUrl = localStorage.getItem('back-url');
    if (backUrl) {
      localStorage.removeItem('back-url');
      navigate(backUrl);
    } else {
      navigate('/', {replace: true});
    }
  }

  const showGenericError = useCallback(() => {
    toast.error(
      `There was an issue logging you in. Please try again later or contact us for support.`,
    );
  }, []);

  // Handler
  const handleFormSubmit = useCallback(
    async (formData: any, actions: any) => {
      try {
        await logInUser('email', {user: formData});
      } catch (error) {
        const {message} = error;
        console.log('message', message);
        // Handle invalid email or password
        if (message?.toLowerCase().includes('invalid email or password')) {
          actions.setFieldError('email', true);
          actions.setFieldError('password', 'Incorrect email or password');
        } else {
          showGenericError();
        }
        actions.setSubmitting(false);
      }
    },
    [logInUser, showGenericError],
  );

  const onSocialAuthSuccess = useCallback(
    async (provider: SocialAuthProviderType, data: any) => {
      try {
        // Attempt the login request
        await logInUser(provider, data);
      } catch (error) {
        // Error performing the login request
        showGenericError();
      }
    },
    [logInUser, showGenericError],
  );

  const onSocialAuthError = useCallback(() => {
    showGenericError();
  }, [showGenericError]);

  const form = useFormik({
    onSubmit: handleFormSubmit,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email address'),
      password: Yup.string().required('Please enter your password'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <AuthFormLayout
      title="Log In"
      socialAuthButtonConfig={{
        action: 'login',
        onSuccess: onSocialAuthSuccess,
        onError: onSocialAuthError,
      }}>
      <form className="flex flex-col items-center gap-y-8">
        <div className="w-full flex flex-col gap-y-8">
          <TextField
            name="email"
            label="Email"
            type="email"
            size="base"
            mode="formik"
            form={form}
            placeholder="Enter your email address here..."
            required
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            size="base"
            mode="formik"
            form={form}
            placeholder="Enter your password here..."
            required
          />
        </div>
        <Button
          label="Log in"
          category="primary"
          size="base"
          mode="formik"
          form={form}
          loadingLabel="Logging in..."
        />
      </form>
      <span className="w-full flex flex-col xs:flex-row items-center justify-between gap-x-12 gap-y-4">
        <Link to="/welcome">Don't have an account yet?</Link>
        <Link to="/forgot-password">Forgot your password?</Link>
      </span>
    </AuthFormLayout>
  );
};

export default LoginPage;
