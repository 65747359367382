import {useState} from 'react';

import {Formik, type FormikProps, Form} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import LoadingView from 'components/common/LoadingView';
import {InputField, SelectField, SubmitButton} from 'components/forms_fields';
import PageWrapper from 'components/PageWrapper';
import {InlineError} from 'components_sb/feedback';
import {TextAreaField} from 'components_sb/input';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import PresignResponse from 'helpers/PresignResponse';
import Property from 'models/properties/Property';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import ServiceRequestAttachment from 'models/service_requests/ServiceRequestAttachment';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';

const NewServiceRequestPage = () => {
  useTitle('New Maintenance Request');
  usePageVisit('NewServiceRequestPage');

  const {propertyId, tenancyId} = useParams();

  const {currentUser} = useAuth();

  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // We do this just as an easy auth check
  const {isLoading, error} = useQuery(
    `landlord-new-service-request-${propertyId}`,
    async () => {
      const property = await Property.select(['id']).find(propertyId);

      return property.data;
    },
  );

  const handleSubmit = async (formData: any, actions: any) => {
    // Doing this manually, because for some reason it doesn't like automatically
    // assigning the attributes with the nested relationship ?!?!?!?
    const request = new ServiceRequest();
    request.title = formData.title;
    request.description = formData.description;
    request.category = formData.category;
    request.renterContactName = formData.renterContactName;
    request.renterContactPhoneNumber = formData.renterContactPhoneNumber;
    if (request.category === 'Appliance Repair') {
      request.applianceMakeAndModel = formData.applianceMakeAndModel;
    }
    request.tenancyId = tenancyId;

    request.serviceRequestAttachments = formData.serviceRequestAttachments.map(
      (attachmentData: any) => {
        const attach = new ServiceRequestAttachment();
        attach.attachment = attachmentData.attachment;
        attach.taken = 'before';
        attach.serviceRequest = request;

        return attach;
      },
    );

    const result = await request.save({
      with: 'serviceRequestAttachments',
    });

    if (result) {
      await queryClient.invalidateQueries(
        `landlord-new-service-request-${propertyId}`,
      );
      toast.success(
        'Maintenance request succesfully created! The Keyhook maintenance team will now find quotes for this job.',
      );

      TrackingService.trackEvent(
        TrackingService.Event.CreateMaintenanceRequest,
      );

      const path =
        '/properties/' + propertyId + '/service-requests/' + request.id;
      navigate(path, {replace: true});
    } else {
      for (const attachment of request.serviceRequestAttachments) {
        console.log(attachment.errors);
      }
      actions.setSubmitting(false);
    }
  };

  const processAttachments = async (
    formik: FormikProps<any>,
    attachments: FileList,
  ) => {
    setIsUploadingAttachment(true);

    const files = Array.from(attachments);

    for (const attachment of files) {
      if (formik.values.serviceRequestAttachments.length >= 5) {
        setIsUploadingAttachment(false);
        return;
      }

      const presignResponse = await fetch(
        `${API_URL}/presigns/service_request_attachment.json?property_id=${propertyId}&filename=${attachment.name}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': currentUser.meta.authenticationToken,
            'X-USER-EMAIL': currentUser?.email,
          },
        },
      );
      const presignInfo = (await presignResponse.json()) as PresignResponse;
      if (presignInfo) {
        await fetch(presignInfo.url, {
          method: presignInfo.method,
          headers: presignInfo.headers as any,
          body: attachment,
        });

        const url = new URL(presignInfo.url);
        const id = url.pathname.split('/').pop();
        const uploadData = {
          id,
          storage: 'cache',
          metadata: {
            size: attachment.size,
            filename: attachment.name,
            mime_type: attachment.type,
          },
        };

        const values: object[] = formik.values.serviceRequestAttachments;
        values.push({attachment: JSON.stringify(uploadData)});

        formik.setFieldValue('serviceRequestAttachments', values);
      }
    }

    setIsUploadingAttachment(false);
  };

  const removeAttachment = (formik: any, index: number) => {
    const values: object[] = formik.values.serviceRequestAttachments;
    values.splice(index, 1);

    formik.setFieldValue('serviceRequestAttachments', values);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="New Maintenance Request" backEnabled>
        <LoadingView />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper title="New Maintenance Request" backEnabled>
        <Card title="New Maintenance Request">
          <Paragraph size="sm">
            Note: Keyhook does not facilitate urgent maintenance. If you require
            urgent maintenance, please arrange this directly. To find out more,{' '}
            <a
              href="https://www.tenancy.govt.nz/maintenance-and-inspections/repairs-and-damages"
              target="_blank"
              className="link link-primary">
              click here.
            </a>
          </Paragraph>
          <Formik
            initialValues={{
              title: '',
              description: '',
              category: '',
              renterContactName: '',
              renterContactPhoneNumber: '',
              applianceMakeAndModel: '',
              serviceRequestAttachments: [],
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string()
                .required()
                .min(10)
                .max(200)
                .label('Short Description'),
              description: Yup.string().required().min(50).label('Description'),
              category: Yup.string()
                .required()
                .oneOf(ServiceRequest.categoryTypes)
                .label('Category'),
              renterContactName: Yup.string().required().min(1).label('Name'),
              renterContactPhoneNumber: Yup.string()
                .required()
                .min(5)
                .label('Mobile Number'),
              applianceMakeAndModel: Yup.string()
                .test(
                  'Appliance Repair Chosen',
                  'Add the appliance make and model',
                  function (value) {
                    if (this.parent.category === 'Appliance Repair') {
                      return value && value.length > 0;
                    } else {
                      return true;
                    }
                  },
                )
                .label('Appliance Make and Modal'),
              serviceRequestAttachments: Yup.array(
                Yup.object().shape({
                  attachment: Yup.string()
                    .min(1)
                    .required()
                    .label('Attachment'),
                }),
              )
                .label('Attachments')
                .min(1)
                .max(10),
            })}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}>
            {(formik) => (
              <Form>
                <InputField
                  name="title"
                  formik={formik}
                  labelProps={{
                    title: 'Short description',
                  }}
                  placeholder="E.g. Dishwasher not working"
                />

                <div className="mt-2">
                  <TextAreaField
                    name="description"
                    mode="formik"
                    form={formik as any}
                    label="Description"
                    placeholder="Describe the issue in as much detail as possible."
                    rows={4}
                  />
                </div>

                <SelectField
                  name="category"
                  formik={formik}
                  labelProps={{
                    title: 'Category',
                  }}>
                  <option>Choose a category</option>
                  {ServiceRequest.categoryTypes.map((cat) => (
                    <option
                      key={cat}
                      value={cat}
                      selected={formik.values.category === cat}>
                      {cat}
                    </option>
                  ))}
                </SelectField>

                {formik.values.category === 'Appliance Repair' ? (
                  <InputField
                    name="applianceMakeAndModel"
                    formik={formik}
                    labelProps={{
                      title: 'Appliance make and model',
                    }}
                    placeholder="E.g. Bosch H1ZTY"
                  />
                ) : null}

                <strong className="block mt-4">
                  Who should we contact to coordinate the maintenance?
                </strong>

                <Paragraph>
                  Please provide the contact information of who should be
                  contacted to co-ordinate this request. This should preferably
                  be one of your tenants.
                </Paragraph>

                <div>
                  <InputField
                    name="renterContactName"
                    formik={formik}
                    labelProps={{
                      title: 'Name',
                    }}
                    placeholder="E.g. John Smith"
                  />
                  <div className="mt-2">
                    <InputField
                      name="renterContactPhoneNumber"
                      formik={formik}
                      labelProps={{
                        title: 'Mobile number',
                      }}
                      placeholder="E.g. 021 123 4567"
                    />
                  </div>
                </div>

                <strong className="block mt-4">Attachments</strong>

                <p className="mb-4">
                  Please attach at least 1 photo or video.
                  <small className="block text-secondary">
                    Max file size: 10mb image, 200mb video
                  </small>
                </p>

                {isUploadingAttachment && (
                  <span className="block text-success">
                    Processing attachments, please wait.
                  </span>
                )}

                {formik.values.serviceRequestAttachments.map(
                  (obj: any, index: number) => {
                    const json = JSON.parse(obj.attachment);

                    return (
                      <div key={index}>
                        <div className="flex justify-between items-center my-2">
                          <strong>{json.metadata.filename}</strong>
                          <button
                            className="btn btn-error btn-sm"
                            type="button"
                            onClick={() => removeAttachment(formik, index)}>
                            Remove
                          </button>
                        </div>
                        <hr className="bg-gray-200 w-full" />
                      </div>
                    );
                  },
                )}

                {formik.values.serviceRequestAttachments.length < 5 && (
                  <div className="mt-2">
                    <input
                      type="file"
                      multiple
                      accept=".png,.jpeg,.jpg,.mp4,.mov"
                      id="attachments-input"
                      onChange={(e) =>
                        processAttachments(formik, e.target.files)
                      }
                      className="hidden"
                    />
                    <button
                      className="btn btn-neutral btn-sm"
                      type="button"
                      onClick={() =>
                        document.getElementById('attachments-input').click()
                      }>
                      Select Files
                    </button>
                  </div>
                )}

                <InlineError error={formik.errors.serviceRequestAttachments} />

                {!isUploadingAttachment && (
                  <SubmitButton
                    className="mt-3"
                    formik={formik}
                    text="Submit"
                    submittingText="Submitting..."
                  />
                )}
              </Form>
            )}
          </Formik>
        </Card>
      </PageWrapper>
    );
  }
};

export default NewServiceRequestPage;
