import React from 'react';

import moment from 'moment';
import {HiOutlineCurrencyDollar} from 'react-icons/hi';

import {Card, TableComponents} from 'components_sb/layout';
import {Link} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import TenancyMembership from 'models/properties/TenancyMembership';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {removeUnderscores, titleize, toCurrency} from 'utilities/StringHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const UnpaidRentCard = ({
  tenancyMembership: data,
}: {
  tenancyMembership: TenancyMembership;
}) => {
  if (!data.tenancy.managedRentEnabled) {
    return null;
  }
  if (data.notPaidRentPayments.length === 0) {
    return null;
  }

  return (
    <Card title="Unpaid rent payments" icon={HiOutlineCurrencyDollar}>
      <Paragraph>
        You have{' '}
        <strong>
          {data.notPaidRentPayments.length} unpaid rent payment(s)
        </strong>
        . If you think this is a mistake,{' '}
        <Link
          to="https://help.keyhook.com/financials/why-does-keyhook-tell-me-my-rent-is-overdue-after-i-ve-paid-it"
          openInNewTab>
          click here
        </Link>
      </Paragraph>
      <Table>
        {/* Table head */}
        <TableHead>
          <TableHeadItem>Due Date</TableHeadItem>
          <TableHeadItem>Status</TableHeadItem>
          <TableHeadItem>Amount Due</TableHeadItem>
          <TableHeadItem>Amount Paid</TableHeadItem>
        </TableHead>

        {/* Table rows */}
        {data.notPaidRentPayments.map((rp) => (
          <TableRow key={rp.id}>
            <TableRowItem>
              {moment(rp.dueDate).format(DATE_FORMAT)}
              {data.tenancy.weeksRentInAdvance > 0 &&
                moment(rp.dueDate).isBefore(data.tenancy.startDate) && (
                  <span> ( Rent In Advance ) </span>
                )}
            </TableRowItem>
            <TableRowItem>
              {titleize(removeUnderscores(rp.status))}
            </TableRowItem>
            <TableRowItem>{toCurrency(rp.amountDue)}</TableRowItem>
            <TableRowItem>{toCurrency(rp.amountPaid)}</TableRowItem>
          </TableRow>
        ))}
      </Table>
    </Card>
  );
};

export default UnpaidRentCard;
