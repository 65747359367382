import {useCallback, useMemo, useState} from 'react';

import {Transition} from '@headlessui/react';
import {type IconType} from '@react-icons/all-files';
import {HiCheckCircle} from '@react-icons/all-files/hi/HiCheckCircle';
import clsx from 'clsx';
import {FaFacebook, FaApple} from 'react-icons/fa';
import {FcGoogle} from 'react-icons/fc';

import {Button} from 'components_sb/buttons';
import {SpinningLoader} from 'components_sb/feedback';
import {Paragraph} from 'components_sb/typography';
import SocialAuth, {
  type SocialAuthProviderType,
} from 'utilities/auth/SocialAuth';
import {SocialAuthUserData} from 'utilities/auth/SocialAuth/providers';

/**
 * Tailwind class configuration
 */
const classes = {
  // Classes for all permutations
  base: 'shadow-md',
};

/**
 * The callback invoked by the social auth handler upon success.
 */
interface SuccessCallback {
  (provider: SocialAuthProviderType, userData: SocialAuthUserData): void;
}

/**
 * The callback invoked by the social auth handler upon an error.
 */
interface ErrorCallback {
  (provider: SocialAuthProviderType): void;
}

export interface SocialAuthButtonConfig {
  /**
   * The action being performed by the button.
   */
  action: 'login' | 'register';
  /**
   * A callback function that is invoked upon successful authentication.
   */
  onSuccess: SuccessCallback;
  /**
   * A callback function that is invoked upon authentication failure.
   */
  onError: ErrorCallback;
}

interface SocialAuthButtonProps {
  /**
   * The auth provider to use for the button.
   */
  provider: SocialAuthProviderType;
  /**
   * Configuration for the social authentication.
   */
  config: SocialAuthButtonConfig;
}

/**
 * Configuration for an auth provider at a component level.
 */
interface AuthProviderConfig {
  label: string;
  icon: IconType;
  classes: string;
}

/**
 * Definitions for each of the auth providers.
 */
const AUTH_PROVIDER_CONFIGS: Record<string, AuthProviderConfig> = {
  [SocialAuth.Provider.Facebook]: {
    label: 'Facebook',
    icon: FaFacebook,
    classes:
      'bg-[#1877F2] border-[#1877F2] hover:bg-[#3587f3] hover:border-[#3587f3] text-white',
  },
  [SocialAuth.Provider.Google]: {
    label: 'Google',
    icon: FcGoogle,
    classes:
      'bg-white border-[#f7f7f7] hover:bg-[#eeeeee] hover:border-[#eeeeee] text-[#757575]',
  },
  [SocialAuth.Provider.Apple]: {
    label: 'Apple',
    icon: FaApple,
    classes:
      'bg-black border-black hover:bg-[#262626] hover:border-[#262626] text-white',
  },
};

/**
 * A button for authenticating a user via a social platform.
 */
const SocialAuthButton = ({provider, config}: SocialAuthButtonProps) => {
  const providerConfig = AUTH_PROVIDER_CONFIGS[provider];

  const labelPrefix = useMemo(
    () => (config.action === 'register' ? 'Register with' : 'Log in with'),
    [config.action],
  );

  const [waiting, setWaiting] = useState(false);
  const [success, setSuccess] = useState(false);

  /**
   * Intercept the success callback.
   */
  const onSuccess = useCallback<SuccessCallback>(
    (...args) => {
      setSuccess(true);
      setWaiting(false);
      config.onSuccess(...args);
    },
    [config],
  );

  /**
   * Intercept the error callback.
   */
  const onError = useCallback<ErrorCallback>(
    (...args) => {
      setWaiting(false);
      config.onError(...args);
    },
    [config],
  );

  const onClick = useCallback(() => {
    setWaiting(true);
    SocialAuth.authenticate(provider, onSuccess, onError);
  }, [provider, onSuccess, onError]);

  return (
    <>
      <Button
        label={`${labelPrefix} ${providerConfig.label}`}
        category="custom"
        customClasses={clsx(classes.base, providerConfig.classes)}
        size="base"
        mode="manual"
        onClick={onClick}
        icon={providerConfig.icon}
      />
      <Transition
        show={waiting || success}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={clsx(
          'fixed z-50 top-0 left-0',
          'w-screen h-screen',
          'bg-brand-50 bg-opacity-80',
          'flex justify-center items-center',
        )}>
        {waiting && (
          <SpinningLoader
            color="brand"
            size="lg"
            message={`Waiting for ${providerConfig.label}...`}
          />
        )}
        {success && (
          <div className="flex flex-col gap-y-6 items-center justify-center text-center">
            <div className="text-green-600 flex flex-row gap-x-2 items-center justify-center">
              <HiCheckCircle className="w-10 h-10" />
              <span className="text-xl">Success!</span>
            </div>
            <Paragraph secondary>
              You will be redirected in a few moments...
            </Paragraph>
          </div>
        )}
      </Transition>
    </>
  );
};

export default SocialAuthButton;
