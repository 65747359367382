import {useEffect} from 'react';

import {useMutation} from 'react-query';
import {useParams} from 'react-router';

import {ResultMessage, SpinningLoader} from 'components_sb/feedback';
import {API_URL} from 'globals/app-globals';

const ConfirmPrivateViewingPage = () => {
  const {publicAccessToken} = useParams();

  /**
   * Mutation for confirming the private viewing.
   */
  const {
    mutate: confirmViewing,
    isLoading: isConfirming,
    isError: errorConfirming,
    isSuccess: successfullyConfirmed,
  } = useMutation(async () => {
    const response = await fetch(
      `${API_URL}/private_viewings/${publicAccessToken}/mark_as_confirmed.json`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to confirm viewing');
    }
  });

  /**
   * Confirm the viewing when the page loads.
   */
  useEffect(() => {
    confirmViewing();
  }, [confirmViewing]);

  return (
    <div className="flex flex-col">
      {isConfirming && (
        <SpinningLoader
          color="brand"
          size="lg"
          message="Confirming your private viewing..."
        />
      )}
      {errorConfirming && (
        <ResultMessage type="error">
          Sorry, there was an issue confirming your private viewing.
        </ResultMessage>
      )}
      {successfullyConfirmed && (
        <ResultMessage type="success">
          Your private viewing has been confirmed!
        </ResultMessage>
      )}
    </div>
  );
};

export default ConfirmPrivateViewingPage;
