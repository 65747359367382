import {useCallback, useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import {
  DocumentDuplicateIcon,
  EyeIcon,
  HomeIcon,
  ChatAltIcon,
} from '@heroicons/react/outline';
import {sentenceCase} from 'change-case';
import clsx from 'clsx';
import moment from 'moment';
import {HiOutlineEye, HiOutlineUpload} from 'react-icons/hi';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router';

import LoadingView from 'components/common/LoadingView';
import ManageListingMenu from 'components/listing/ManageListingMenu';
import PremiumListingPlatformsPublishModal from 'components/modals/PremiumListingPlatformsPublishModal';
import PageWrapper from 'components/PageWrapper';
import {Button} from 'components_sb/buttons';
import {Alert} from 'components_sb/feedback';
import {Card, Modal, TableComponents} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import listingPlatforms from 'constants/listing-platforms';
import Listing, {ListingStatus} from 'models/listings/Listing';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit} from 'utilities/hooks';

import FacebookGroupsCard from './FacebookGroupsCard';

export type ListingPlatformStatus = 'live' | 'pending' | 'disabled';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const {useModal} = Modal.Imperative;

interface PublishToPremiumPlatformProps {
  listing: Listing;
}

const PublishToPremiumPlatform = ({listing}: PublishToPremiumPlatformProps) => {
  const openModal = useModal();

  const onPublish = useCallback(() => {
    openModal(PremiumListingPlatformsPublishModal, {
      listing,
    });
  }, [openModal, listing]);

  return Capacitor.isNativePlatform() ? (
    <>
      <Paragraph size="sm">
        Currently, posting your listing to TradeMe and homes.co.nz is not
        possible through our mobile app.
      </Paragraph>
      <Paragraph size="sm">
        Please visit Keyhook using your desktop web browser.
      </Paragraph>
    </>
  ) : (
    <div>
      <Button
        label="Publish"
        icon={HiOutlineUpload}
        category="primary"
        size="base"
        mode="manual"
        onClick={onPublish}
      />
    </div>
  );
};

const ListingDetailPage = () => {
  usePageVisit('ListingDetailPage');
  const {propertyId, listingId: listingPublicId} = useParams();

  const {data, isLoading, isSuccess, isError, error} = useQuery(
    ['listing-platforms', {listingPublicId}],
    async () => {
      const response = await Listing.select({
        listings: [
          'id',
          'created_at',
          // 'published_at' TODO: Track this when the user hits publish
          'public_id',
          'view_count',
          'status',
          'property_id',
          'pets_allowed',
          'street_address',
          'city',
          'country',
          'bedrooms',
          'chosen_external_listing_sites',
          'enquiry_threads_count',
          'listing_rental_applications_count',
          'open_homes_count',
          'private_viewings_count',
        ],
      })
        .includes(['external_listing_ads'])
        .selectExtra(['region'])
        .find(listingPublicId);

      return response;
    },
  );

  const {data: listing, meta} = data ?? {};

  const viewCount = useMemo(
    () =>
      !isSuccess
        ? 0
        : Number(listing.viewCount) +
          listing.externalListingAds.reduce(
            (sum, ad) => sum + Number(ad.viewCount),
            0,
          ),
    [isSuccess, listing],
  );

  const listingPlatformRows = useMemo(
    () =>
      !isSuccess
        ? []
        : listingPlatforms.map((platform) => {
            /**
             * Determine whether the listing platform has
             * been enabled for the listing.
             */
            const disabled =
              /**
               * Implict platforms that are not published by another site cannot be disabled
               */
              !(platform.implicit && platform.publishedBySite === null) &&
              /**
               *  Other platforms are disabled if either the platform or the platform it is publihsed
               * by (for implicit sites) has not been added to the chosen external listing sites
               */
              !listing.chosenExternalListingSites.includes(
                platform.implicit ? platform.publishedBySite : platform.site,
              );

            /**
             * Attempt to find an ad for the platform or the platform it is publihsed
             * by (for implicit sites) to determine the status.
             */
            const ad = listing.externalListingAds?.find(
              (ad) =>
                ad.site ===
                (platform.implicit ? platform.publishedBySite : platform.site),
            );

            /**
             * Determine the status.
             */
            let status: ListingPlatformStatus;
            if (disabled) {
              status = 'disabled';
            } else {
              /**
               * If the listing itself has not yet been approved, then all
               * listing platforms will be pending.
               */
              if (listing.status !== 'active') {
                status = 'pending';
              } else {
                /**
                 * If the platform is Keyhook, then the listing is live by default.
                 */
                if (platform.site === 'keyhook.com') {
                  status = 'live';
                  /**
                   * Since listing URLs for Trovit and Mitula are not set
                   * in the database once posted, we assume these are live
                   * after 48 hours since the Keyhook listing was created.
                   */
                } else if (
                  ['trovit.com', 'homes.mitula.co.nz'].includes(
                    platform.site,
                  ) &&
                  !!listing.createdAt &&
                  moment
                    .duration(moment().diff(moment(listing.createdAt)))
                    .asHours() >= 48
                ) {
                  status = 'live';
                } else {
                  /**
                   * If there is an external listing advertisement item,
                   * then the listing is live on the platform.
                   */
                  status = ad ? 'live' : 'pending';
                }
              }
            }

            /**
             * Determine the URL for the listing.
             */
            let listingUrl = null;
            if (
              // All live listings should have a listing URL.
              status === 'live'
            ) {
              /**
               * Ensure the that ad is for the current platform and not the platform
               * that published to the platform if implicit.
               */
              if (!!ad && ad.site === platform.site && ad?.link) {
                listingUrl = ad.link;
              } else if (platform.fallbackLinkGenerator) {
                listingUrl = platform.fallbackLinkGenerator(listing);
              }
            }

            return {
              platform,
              status,
              listingUrl,
            };
          }),
    [listing, isSuccess],
  );

  const navigate = useNavigate();

  const onResumeListingSetup = useCallback(() => {
    localStorage.setItem('new-property-id', propertyId);
    navigate('/listings/new');
  }, [navigate, propertyId]);

  return (
    <PageWrapper title="Listing Dashboard" backEnabled>
      {/* Error view */}
      {isError && errorViewForError(error)}

      {/* Loading view */}
      {isLoading && <LoadingView />}

      {/* Main content */}
      {isSuccess && (
        <>
          <ManageListingMenu
            propertyId={propertyId}
            listingPublicId={listingPublicId}
          />

          <Alert
            type="warning"
            title="This listing has not yet been published."
            show={listing.isDraft}
            actions={[
              {
                label: 'Finish my listing',
                onClick: onResumeListingSetup,
              },
            ]}
          />

          <div className="w-full stats mmd:grid-flow-row shadow-xl mb-8 mt-4">
            <div className="stat place-items-center">
              <div className="stat-figure text-secondary">
                <EyeIcon className="w-8 text-secondary" />
              </div>
              <div className="stat-title">Views</div>
              <div className="stat-value">{viewCount}</div>
              <div className="stat-desc">In Total</div>
            </div>
            <div className="stat place-items-center">
              <div className="stat-figure text-secondary">
                <DocumentDuplicateIcon className="w-8 text-secondary" />
              </div>
              <div className="stat-title">Applications</div>
              <div className="stat-value">
                {listing.listingRentalApplicationsCount}
              </div>
              <div className="stat-desc">Received</div>
            </div>
            <div className="stat place-items-center">
              <div className="stat-figure text-secondary">
                <ChatAltIcon className="w-8 text-secondary" />
              </div>
              <div className="stat-title">Enquiries</div>
              <div className="stat-value">{listing.enquiryThreadsCount}</div>
              <div className="stat-desc">Received</div>
            </div>
            <div className="stat place-items-center">
              <div className="stat-figure text-secondary">
                <HomeIcon className="w-8 text-secondary" />
              </div>
              <div className="stat-title">Viewings</div>
              <div className="stat-value">
                {listing.openHomesCount + listing.privateViewingsCount}
              </div>
              <div className="stat-desc">Scheduled</div>
            </div>
          </div>
          {/* Pending approval */}
          {listing.status === ListingStatus.PendingApproval && (
            <Alert
              type="info"
              title="Your listing has been submitted and is pending approval."
              description="You will be notified once it has been approved."
            />
          )}
          <Card title="Listing Advertisments">
            <Paragraph>
              Below are the details for listings of your property across
              platforms.
            </Paragraph>
            <Table>
              <TableHead>
                <TableHeadItem>Platform</TableHeadItem>
                <TableHeadItem>Status</TableHeadItem>
                <TableHeadItem>Actions</TableHeadItem>
              </TableHead>
              {listingPlatformRows.map(({platform, status, listingUrl}) => (
                <TableRow key={platform.site}>
                  <TableRowItem>
                    <div className="h-16 w-40">
                      <img
                        src={platform.logo}
                        alt={platform.label}
                        className="h-16 object-contain object-left w-auto"
                      />
                    </div>
                  </TableRowItem>
                  <TableRowItem>
                    <div className="flex flex-col gap-y-2 max-w-[200px]">
                      <div className="flex flex-row">
                        <div
                          className={clsx(
                            'flex flex-row items-center gap-x-2',
                            'rounded-lg px-3 py-1',
                            'text-sm font-medium',
                            status === 'live' && 'bg-green-500 text-white',
                            status === 'pending' &&
                              'bg-amber-400 text-amber-900',
                            status === 'disabled' &&
                              'bg-brand-75 text-brand-850 text-opacity-40',
                          )}>
                          <span>{sentenceCase(status)}</span>
                        </div>
                      </div>
                      {status === 'pending' && (
                        <Paragraph size="xs" secondary>
                          Please allow 24 - 48 hours for your listing to appear.
                        </Paragraph>
                      )}
                    </div>
                  </TableRowItem>
                  <TableRowItem>
                    {status === 'disabled' ? (
                      platform.premium && (
                        <PublishToPremiumPlatform listing={listing} />
                      )
                    ) : (
                      <>
                        {listing.status === ListingStatus.PendingApproval &&
                        platform.site === 'keyhook.com' ? (
                          <Button
                            label="Preview listing"
                            icon={HiOutlineEye}
                            category="primary"
                            size="base"
                            mode="link"
                            linkTo={`/listings/${listing.publicId}`}
                          />
                        ) : (
                          <Button
                            label="View listing"
                            icon={HiOutlineEye}
                            category="primary"
                            size="base"
                            mode="link"
                            linkTo={listingUrl}
                            disabled={status === 'pending' || !listingUrl}
                          />
                        )}
                      </>
                    )}
                  </TableRowItem>
                </TableRow>
              ))}
            </Table>
          </Card>

          {/* Share to Facebook groups */}
          {<FacebookGroupsCard listing={listing} />}
        </>
      )}
    </PageWrapper>
  );
};

export default ListingDetailPage;
