import {useMemo} from 'react';

import clsx from 'clsx';
import Decimal from 'decimal.js';
import moment from 'moment';
import {
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentText,
} from 'react-icons/hi';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';

import LoadingView from 'components/common/LoadingView';
import PageWrapper from 'components/PageWrapper';
import DocumentsCard from 'components/property/landlord/detail_page_cards/DocumentsCard';
import InspectionsCard from 'components/property/landlord/detail_page_cards/InspectionsCard';
import MaintenanceRequestsCard from 'components/property/landlord/detail_page_cards/MaintenanceRequestsCard';
import TenantsCard from 'components/property/landlord/detail_page_cards/TenantsCard';
import LandlordPropertyDetailCard from 'components/property/landlord/PropertyDetailCard';
import EndOfTenancyCard from 'components/property/renter/detail_page_cards/EndOfTenancyCard';
import RentCard from 'components/property/renter/detail_page_cards/RentCard';
import UnpaidBondCard from 'components/property/renter/detail_page_cards/UnpaidBondCard';
import UnpaidRentCard from 'components/property/renter/detail_page_cards/UnpaidRentCard';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import TenancyContinuation from 'models/properties/TenancyContinuation';
import TenancyMembership from 'models/properties/TenancyMembership';
import useAuth from 'services/useAuth';
import {Action} from 'types/actions';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {usePageVisit, useTitle} from 'utilities/hooks';
import {toCurrency} from 'utilities/StringHelpers';

const TenancyDetailPage = () => {
  useTitle('Tenancy');
  usePageVisit('TenancyDetailPage');

  const {id} = useParams();

  const {currentUser} = useAuth();

  const {data, isLoading, error, isSuccess} = useQuery(
    `renter-tenancy-detail-${id}`,
    async () => {
      const res = await TenancyMembership.select({
        tenancy_memberships: [
          'active',
          'rent_split',
          'bond_split',
          'bond_paid',
          'bond_reference',
          'rent_reference',
          'created_at',
        ],
        tenancies: [
          'status',
          'bond',
          'start_date',
          'end_date',
          'total_rent',
          'rental_period',
          'head_tenant_id',
          'created_at',
          'weeks_rent_in_advance',
          'managed_rent_enabled',
          'lease_document_type',
        ],
        properties: [
          'street_address',
          'suburb',
          'city',
          'main_image',
          'bedrooms',
          'bathrooms',
          'garages',
        ],
        users: ['name', 'avatar'],
        documents: [
          'document',
          'document_type',
          'name',
          'created_at',
          'documentable_id',
          'documentable_type',
        ],
      })
        .includes({
          tenancy: [
            {property: ['documents', 'landlord']},
            {tenancy_memberships: 'renter'},
            {tenancy_invites: 'renter'},
            'inspections',
            'documents',
            'active_tenancy_continuation',
            'service_requests',
            'unprocessed_rent_price_changes',
            'end_of_tenancy',
          ],
          not_paid_rent_payments: 'late_payment_notifications',
        })
        .where({tenancy_id: id, renter_id: currentUser.id})
        .first();

      if (res.data) {
        return res.data;
      } else {
        throw new Error('not found');
      }
    },
    {retry: 1},
  );

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !isSuccess || !currentUser || !data.tenancy
        ? null
        : [
            ...(data.tenancy.headTenantId === currentUser.id
              ? [
                  {
                    label: 'Update rent amounts',
                    linkTo: `/tenancies/${id}/edit-rent-amounts`,
                  },
                ]
              : []),

            /**
             * Give Notice
             */
            ...(data.tenancy.isActive
              ? [
                  {
                    label: 'Give Notice',
                    icon: HiOutlineDocumentText,
                    linkTo: `notices/new`,
                  },
                ]
              : []),

            /**
             * View notices
             * */
            {
              label: 'View notices',
              icon: HiOutlineDocumentDuplicate,
              linkTo: `notices`,
            },

            ...(data.tenancy.headTenantId === currentUser.id
              ? [
                  {
                    label: 'Invite other tenants',
                    linkTo: `/tenancies/${id}/invite-tenants`,
                  },
                ]
              : []),
          ],
    [data, isSuccess, id, currentUser],
  );

  const tenancyRenewalCard = () => {
    let renewal: TenancyContinuation;
    if (data.tenancy && data.tenancy.activeTenancyContinuation) {
      renewal = data.tenancy.activeTenancyContinuation;
    }
    if (!renewal || !renewal.landlordRequestedAction) {
      return null;
    }

    const isHeadTenant = data?.tenancy.headTenantId == currentUser?.id;

    const renderHeadTenantInformation = () => {
      if (!renewal.tenantChosenAction) {
        return (
          <div>
            <Paragraph>
              Your landlord has decided to {renewal.landlordActionString},
              please discuss this with the other tenants and then click the
              button below to make a decision.
            </Paragraph>

            <Paragraph>
              If you do not wish to renew this tenancy after the end date,
              choose the "Give Notice" option from the actions dropdown.
            </Paragraph>

            <Link to={`tenancy-renewals/${renewal.id}/edit`}>
              <button className="btn btn-sm btn-neutral">
                Respond to landlord's decision
              </button>
            </Link>
          </div>
        );
      } else if (
        renewal.landlordRequestedAction === renewal.tenantChosenAction
      ) {
        return (
          <div>
            <Paragraph>
              Your landlord has decided to {renewal.landlordActionString}, which
              you have agreed to.
            </Paragraph>

            {renewal.tenantChosenAction === 'start_new_lease' &&
              !renewal.tenantsWhoHaveSigned.includes(currentUser.id) && (
                <div>
                  <Paragraph>
                    You have not yet signed the new lease, click the button
                    below to review.
                  </Paragraph>

                  <Link to={`tenancy-renewals/${renewal.id}/edit`}>
                    <button className="btn btn-sm btn-neutral">
                      Review New Lease
                    </button>
                  </Link>
                </div>
              )}
          </div>
        );
      } else if (
        renewal.landlordRequestedAction !== renewal.tenantChosenAction
      ) {
        return (
          <Paragraph>
            Your landlord has decided to {renewal.landlordActionString}, however
            you chose to go periodic instead.
          </Paragraph>
        );
      }

      return null;
    };

    const renderRegularTenantInformation = () => {
      if (!renewal.tenantChosenAction) {
        return (
          <Paragraph>
            Your landlord has decided to {renewal.landlordActionString}, please
            discuss this with the other tenants, your head tenant will be
            responsible for confirming or rejecting this.
          </Paragraph>
        );
      } else if (
        renewal.landlordRequestedAction === renewal.tenantChosenAction
      ) {
        return (
          <Paragraph>
            Your landlord has decided to {renewal.landlordActionString}, which
            your head tenant has agreed to.
          </Paragraph>
        );
      } else if (
        renewal.landlordRequestedAction !== renewal.tenantChosenAction
      ) {
        return (
          <Paragraph>
            Your landlord has decided to {renewal.landlordActionString}, however
            your head tenant has chosen to go periodic instead.
          </Paragraph>
        );
      }

      return null;
    };

    const signingStatus = () => {
      if (
        !renewal.tenantChosenAction ||
        renewal.landlordRequestedAction === 'go_periodic' ||
        renewal.tenantChosenAction === 'go_periodic'
      ) {
        return null;
      }

      if (renewal.allTenantsSigned) {
        return <Paragraph>All tenants have signed the renewal.</Paragraph>;
      } else {
        return (
          <div>
            <Paragraph>
              We are currently waiting for all tenants to sign this renewal,
              otherwise the tenancy will go periodic after the end date.
            </Paragraph>

            {!renewal.tenantsWhoHaveSigned.includes(currentUser.id) && (
              <div>
                <Paragraph>
                  You have not yet signed the new lease, click the button below
                  to review and sign.
                </Paragraph>

                <Link to={`tenancy-renewals/${renewal.id}/edit`}>
                  <button className="btn btn-sm btn-neutral">
                    Review New Lease
                  </button>
                </Link>
              </div>
            )}
          </div>
        );
      }
    };

    return (
      <Card title="Renewal Options" className="mt-4 border-t-3 border-info">
        {isHeadTenant
          ? renderHeadTenantInformation()
          : renderRegularTenantInformation()}

        {signingStatus()}
      </Card>
    );
  };

  const rentPriceChangesCard = () => {
    if (
      data.tenancy &&
      data.tenancy.unprocessedRentPriceChanges &&
      data.tenancy.unprocessedRentPriceChanges.length > 0
    ) {
      const rentChange = data.tenancy.unprocessedRentPriceChanges[0];
      const newPersonalRent = new Decimal(data.rentSplit)
        .add(new Decimal(rentChange.newRentSplits[currentUser.id]))
        .toString();

      return (
        <Card title="Rent Change">
          <Paragraph>
            Your landlord has given notice that the rent for this tenancy will
            change to {toCurrency(rentChange.newTotalRent)} from{' '}
            {moment(rentChange.effectiveDate).format(DATE_FORMAT)} onwards.
          </Paragraph>

          <Paragraph>
            Your personal rent will change to {toCurrency(newPersonalRent)},
            please make sure to update any recurring payments.
          </Paragraph>

          {rentChange.isTemporaryRentReduction && (
            <Paragraph>
              This is a temporary rent reduction. The rent will change back to{' '}
              {toCurrency(rentChange.oldTotalRent)} on{' '}
              {moment(rentChange.reductionEndDate).format(DATE_FORMAT)}.
            </Paragraph>
          )}
        </Card>
      );
    } else {
      return null;
    }
  };

  const lgBreakpoint = useTailwindBreakpoint('md');

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return (
      <PageWrapper title="Tenancy">
        <LoadingView />
      </PageWrapper>
    );
  } else {
    const property = data.tenancy.property;
    return (
      <PageWrapper
        title="Tenancy"
        loading={isLoading}
        actions={pageActions}
        showButtonsAsDropDown={true}
        dropdownTitle="Actions"
        disablePadding={{
          banner: !lgBreakpoint,
          content: false,
        }}
        banner={{
          container: ({children}) => (
            <div className="relative">
              {/* Blurred main image as the background banner on large screens */}
              <div className="hidden md:flex absolute z-0 top-0 left-0 w-full h-full overflow-hidden">
                <div
                  className="bg-cover bg-center blur-lg -mx-6 -my-6"
                  style={{
                    backgroundImage: `url(${property.mainImage})`,
                    /**
                     * Extend the image outside of the container so that we don't
                     * see the blurred edges.
                     */
                    width: 'calc(100% + 48px)',
                    height: 'calc(100% + 48px)',
                  }}
                />
              </div>
              {/* Add an inner shadow to the bottom of the banner on large screens */}
              <div
                className={clsx(
                  'hidden md:flex',
                  'absolute z-10 bottom-0 left-0',
                  'w-full h-16',
                  'bg-gradient-to-t from-neutral-800 to-transparent',
                  'origin-bottom',
                  'scale-y-100 opacity-[30%]',
                )}
              />
              {/* Render the banner content at the same width as the page content */}
              <div
                className={clsx(
                  'py-0 md:py-8 flex items-center w-full h-full relative',
                  'border-b-2 md:border-none border-brand-50',
                )}>
                {children}
              </div>
            </div>
          ),
          content: (
            <LandlordPropertyDetailCard
              property={data.tenancy.property}
              tenancy={data.tenancy}
            />
          ),
        }}>
        <EndOfTenancyCard tenancy={data.tenancy} />

        {tenancyRenewalCard()}
        {rentPriceChangesCard()}

        <UnpaidBondCard tenancyMembership={data} />
        <UnpaidRentCard tenancyMembership={data} />
        <RentCard tenancyMembership={data} />

        <MaintenanceRequestsCard tenancy={data.tenancy} />
        <InspectionsCard tenancy={data.tenancy} />
        <DocumentsCard
          tenancy={data.tenancy}
          property={data.tenancy.property}
        />
        <TenantsCard tenancy={data.tenancy} property={data.tenancy.property} />
      </PageWrapper>
    );
  }
};

export default TenancyDetailPage;
