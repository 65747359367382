import {useCallback, useImperativeHandle} from 'react';

import Decimal from 'decimal.js';
import {useFormik} from 'formik';
import {isEmpty} from 'lodash';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {DateField, InputField, SelectField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import MoneyField from 'components/forms_fields/MoneyField';
import {ModalDefinition} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import AccountingRecurringRecord from 'models/accounting/AccountingRecurringRecord';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';
import {roundMoney} from 'utilities/MathHelpers';

interface FormValues {
  nextTriggerDate: string;

  propertyId: string;

  subtotalAmount: Decimal | null;
  gstAmount: Decimal | null;
  totalAmount: Decimal | null;

  description: string;

  periodNumber: number;
  periodType: string;
}

const AddEditAccountingRecurringModal: ModalDefinition = {
  title: 'Add Recurring Transaction',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onExport',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal, accountingRecurringRecord} = props;

    const model = accountingRecurringRecord as AccountingRecurringRecord;

    const queryClient = useQueryClient();

    const handleSubmit = useCallback(
      async (values: FormValues, formikHelpers: any) => {
        model.assignAttributes(values);

        const result = await model.save();

        if (result) {
          await queryClient.invalidateQueries('landlord-accounting-stats');
          await queryClient.invalidateQueries('landlord-accounting-table');
          toast.success('Transaction sucessfully added');

          TrackingService.trackEvent(
            TrackingService.Event.AddRecurringAccountingRecord,
          );

          closeModal();
        } else {
          for (const key of Object.keys(model.errors)) {
            const message = model.errors[key].fullMessage;
            formikHelpers.setFieldError(key, message);
          }
          formikHelpers.setSubmitting(false);
        }
      },
      [closeModal, model, queryClient],
    );

    const formik = useFormik<FormValues>({
      initialValues: {
        nextTriggerDate: model.nextTriggerDate || '',
        propertyId: model.propertyId || '',
        subtotalAmount: model.subtotalAmount
          ? new Decimal(model.subtotalAmount)
          : null,
        gstAmount: model.gstAmount ? new Decimal(model.gstAmount) : null,
        totalAmount: model.totalAmount ? new Decimal(model.totalAmount) : null,
        description: model.description || '',

        periodNumber: model.periodNumber || 1,
        periodType: model.periodType || 'weeks',
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        nextTriggerDate: Yup.date().required().label('Next transaction date'),
        description: Yup.string().required().min(4).label('Description'),
        subtotalAmount: Yup.number().required().nullable().label('Subtotal'),
        gstAmount: Yup.number()
          .required()
          .nullable()
          .optional()
          .label('GST')
          .test(
            'is valid amount',
            'GST must be zero or equal to 15% of the subtotal',
            function (value) {
              const val = new Decimal(value);
              const subtotal = new Decimal(this.parent.subtotalAmount);

              return (
                val.equals(new Decimal(0)) ||
                val.equals(subtotal.mul(0.15).abs().toDecimalPlaces(2))
              );
            },
          ),
        totalAmount: Yup.number()
          .required()
          .label('Total')
          .nullable()
          .optional()
          .test(
            'is valid amount',
            'Total should equal subtotal plus gst',
            function (value) {
              const val = new Decimal(value).abs();
              const subtotal = new Decimal(this.parent.subtotalAmount).abs();
              const gst = new Decimal(this.parent.gstAmount).abs();

              return val.equals(subtotal.add(gst).toDecimalPlaces(2));
            },
          ),

        property_id: Yup.string().optional().nullable().label('Property'),
        periodNumber: Yup.number().required().nullable().label('Repeat Amount'),
        periodType: Yup.string()
          .required()
          .nullable()
          .label('Repeat period')
          .oneOf(['weeks', 'months', 'years']),
      }),
    });

    const {data: properties} = useQuery(
      'landlord-financials-properties-add-transaction',
      async () => {
        const properties = await Property.select([
          'id',
          'street_address',
        ]).all();

        return properties.data;
      },
      {
        onSuccess: (properties) => {
          if (!formik.values.propertyId && properties.length > 0) {
            formik.setFieldValue('propertyId', properties[0].id);
          }
        },
      },
    );

    const onExport = useCallback(async () => {
      const result = await formik.validateForm();
      if (isEmpty(result)) {
        const {setSubmitting, setFieldError} = formik;
        await handleSubmit(formik.values, {setSubmitting, setFieldError});
      }
      return false;
    }, [formik, handleSubmit]);

    useImperativeHandle(ref, () => ({
      onExport,
    }));

    return (
      <>
        <FormRow responsive>
          <FormRowItem>
            <DateField
              formik={formik}
              name="nextTriggerDate"
              label="Next Transaction Date"
              helpText="The next date this transaction will take place."
              minDate={new Date('2010-01-01')}
              maxDate={new Date('2100-01-01')}
            />
          </FormRowItem>
          <FormRowItem>
            <SelectField
              name="property_id"
              formik={formik}
              labelProps={{title: 'Property'}}>
              {properties &&
                properties.length > 0 &&
                properties.map((prop) => (
                  <option key={prop.id} value={prop.id}>
                    {prop.streetAddress}
                  </option>
                ))}
            </SelectField>
          </FormRowItem>
        </FormRow>

        <div className="mt-2">
          <Paragraph>
            If you select a date in the past, we will generate backdated
            transactions until today. This may take a few minutes to process.
          </Paragraph>
        </div>

        <FormRow responsive>
          <FormRowItem>
            <MoneyField
              name="subtotalAmount"
              label="Subtotal"
              allowNegative
              type="number"
              placeholder="E.g. 10.00"
              required
              helpText="The amount of the transaction excluding any gst."
              formik={formik}
              value={
                formik.values.subtotalAmount
                  ? formik.values.subtotalAmount.toString()
                  : ''
              }
              onValuesChange={(values) =>
                formik.setFieldValue(
                  'subtotalAmount',
                  new Decimal(values.value),
                )
              }
              onBlur={() => {
                const val = new Decimal(formik.values.subtotalAmount).abs();
                const gstNum = roundMoney(
                  new Decimal(val).mul(0.15).toNumber(),
                  2,
                );

                const gst = new Decimal(gstNum);

                formik.setFieldValue('gstAmount', gst);
                if (formik.values.subtotalAmount.toNumber() < 0) {
                  formik.setFieldValue(
                    'totalAmount',
                    val.add(gst).dividedBy(-1),
                  );
                } else {
                  formik.setFieldValue('totalAmount', val.add(gst));
                }
              }}
            />
          </FormRowItem>

          <FormRowItem>
            <MoneyField
              name="gstAmount"
              label="GST"
              type="number"
              placeholder="E.g. 1.50"
              helpText="Any GST incurred in this transaction. Can be 0.00"
              required
              formik={formik}
              value={
                formik.values.gstAmount
                  ? formik.values.gstAmount.toString()
                  : ''
              }
              onValuesChange={(values) =>
                formik.setFieldValue('gstAmount', new Decimal(values.value))
              }
              onBlur={() => {
                const val = new Decimal(formik.values.gstAmount).abs();
                const subtotal = new Decimal(formik.values.subtotalAmount);

                if (formik.values.subtotalAmount.toNumber() < 0) {
                  formik.setFieldValue(
                    'totalAmount',
                    -val.add(subtotal).dividedBy(-1),
                  );
                } else {
                  formik.setFieldValue('totalAmount', val.add(subtotal));
                }
              }}
            />
          </FormRowItem>

          <FormRowItem>
            <MoneyField
              name="totalAmount"
              label="Total"
              type="number"
              allowNegative
              helpText="The subtotal plus the gst amount"
              placeholder="E.g. 11.50"
              required
              formik={formik}
              value={
                formik.values.totalAmount
                  ? formik.values.totalAmount.toString()
                  : ''
              }
              onValuesChange={(values) =>
                formik.setFieldValue('totalAmount', new Decimal(values.value))
              }
            />
          </FormRowItem>
        </FormRow>

        <div className="mt-2">
          <Paragraph>
            Use a negative amount for subtotal / total to indicate an expense.
          </Paragraph>
        </div>

        <div>
          <InputField
            name="description"
            labelProps={{
              title: 'Description',
            }}
            placeholder="E.g. Rates"
            formik={formik}
          />
        </div>

        <div className="flex flex-col lg:flex-row justify-between items-end space-x-0 lg:space-x-2">
          <FormRowItem>
            <InputField
              name="periodNumber"
              labelProps={{
                title: 'Repeat every',
              }}
              placeholder="Enter a number..."
              formik={formik}
            />
          </FormRowItem>
          <FormRowItem>
            <SelectField formik={formik} name="periodType">
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
            </SelectField>
          </FormRowItem>
        </div>
      </>
    );
  },
};

export default AddEditAccountingRecurringModal;
