import {FunctionComponent, useMemo} from 'react';

import clsx from 'clsx';
import {BiBath, BiBed, BiCar} from 'react-icons/bi';
import Skeleton from 'react-loading-skeleton';
import {Link} from 'react-router-dom';

import Tags from 'components_sb/typography/Tags/Tags';
import Listing, {ListingStatus} from 'models/listings/Listing';

import ListingCardCount from './ListingCardCount';

interface ListingCardProps {
  listing?: Listing;
  openInNewTab?: boolean;
}

const ListingCard: FunctionComponent<ListingCardProps> = ({
  listing,
  openInNewTab = false,
}) => {
  /**
   * Present the card in skeleton mode when a listing
   * has not been provided.
   */
  const showSkeleton = useMemo(() => !listing, [listing]);

  return (
    <Link
      to={showSkeleton ? '' : `/listings/${listing.publicId}`}
      target={openInNewTab ? '_blank' : undefined}
      className={showSkeleton ? 'pointer-events-none' : undefined}>
      <div
        className={clsx(
          '@container',
          'h-full',
          'flex flex-col rounded-2xl overflow-hidden',
          'bg-white',
          'rounded-xl',
          'border-2 border-brand-50',
          'cursor-pointer',
          'transition-all duration-300',
          'drop-shadow-none hover:drop-shadow-2xl',
        )}>
        {/* Image */}
        <div className="w-full h-52 relative">
          {showSkeleton ? (
            <Skeleton
              containerClassName="flex w-full h-full"
              borderRadius={0}
            />
          ) : (
            <>
              {listing.listingPhotos?.length && (
                <img
                  className="object-cover origin-center max-w-full max-h-full w-full h-full"
                  src={listing.listingPhotos[0].photo}
                />
              )}
              {[
                ListingStatus.Active,
                ListingStatus.UnderOffer,
                ListingStatus.Completed,
              ].includes(listing.status) && (
                <div
                  className={clsx(
                    'absolute',
                    'bottom-0 left-0',
                    'w-full',
                    'text-center',
                    'py-1 px-4',
                    'text-xs',
                    listing.status === ListingStatus.Active &&
                      'bg-green-500 text-white',
                    listing.status === ListingStatus.UnderOffer &&
                      'bg-amber-500 text-white',
                    listing.status === ListingStatus.Completed &&
                      'bg-gray-400 text-white',
                  )}>
                  {listing.status === ListingStatus.Active && 'Available'}
                  {listing.status === ListingStatus.UnderOffer && 'Under Offer'}
                  {listing.status === ListingStatus.Completed && 'Rented'}
                </div>
              )}
            </>
          )}
        </div>

        {/* Main card content */}
        <div className="flex-1 p-4 text-brand-850 flex flex-col gap-y-2">
          {/* Tag */}
          <div className="leading-none">
            {showSkeleton ? (
              <Skeleton width={60} height={24} borderRadius={24} />
            ) : (
              <Tags>{[listing.propertyType]}</Tags>
            )}
          </div>
          <div className="flex flex-col">
            {/* Title */}
            <div className="text-xl font-semibold">
              {showSkeleton ? <Skeleton /> : listing.listingTitle}
            </div>
            {/* Subtitle */}
            <div className="text-base opacity-70">
              {showSkeleton ? (
                <Skeleton />
              ) : (
                `${listing.suburb}, ${listing.city}`
              )}
            </div>
          </div>
          <div
            className={clsx(
              'mt-auto',
              'flex',
              'gap-y-3',
              'flex-col @xs:flex-row',
              'justify-start @xs:justify-between',
              'items-start @xs:items-center',
            )}>
            {/* Key stats / features */}
            <div className="flex flex-row gap-x-4">
              {showSkeleton ? (
                <>
                  <Skeleton width={30} />
                  <Skeleton width={30} />
                  <Skeleton width={30} />
                </>
              ) : (
                <>
                  <ListingCardCount count={listing.bedrooms} icon={BiBed} />
                  <ListingCardCount count={listing.bathrooms} icon={BiBath} />
                  <ListingCardCount count={listing.garages} icon={BiCar} />
                </>
              )}
            </div>
            {/* Price per week */}
            {showSkeleton ? (
              <Skeleton width={100} />
            ) : (
              <div className="text-brand-850 leading-none flex flex-row gap-x-1 items-center">
                <span>${Number(listing.rentAmount).toFixed(2)}</span>
                <span className="text-xs opacity-70">per week</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ListingCard;
