import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  FormikConfig,
  FormikValues,
  type FormikHelpers,
  useFormik,
} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import StepContainer from 'components/walkthrough/common/StepContainer';
import {InlineError} from 'components_sb/feedback';
import FileUploader from 'components_sb/input/FileUploader/FileUploader';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import ListingPhoto from 'models/listings/ListingPhoto';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import useAuth from 'services/useAuth';
import {saveResource} from 'utilities/SpraypaintHelpers';

import {createListingPhotosStorageHandler} from './listing-photos-storage-handler';

const PhotosStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll('root-scroll-container');

  /**
   * Find the most recent listing for the property.
   */
  const listing = useMostRecentlyCreated(property.listings);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartUploadPhotosStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );
  }, [property.id, listing.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);
  const [uploadingPhotos, setUploadingPhotos] = useState(false);

  const {currentUser} = useAuth();

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: any, actions: FormikHelpers<any>) => {
      setSubmitting(true);

      const {photos: photosFormValue} = formValues;

      let listingPhotos = photosFormValue.map(
        (photoUrl: string, index: number) => {
          /**
           * Attempt to find an existing listing photo instance for
           * the photo URL.
           */
          const existingPhoto = listing.listingPhotos.find(
            ({photo}) => photo === photoUrl,
          );

          /**
           * Use the existing listing photo instance if found, otherwise
           * create a new listing photo instance.
           */
          const photoInstance =
            existingPhoto ||
            new ListingPhoto({
              photo: photoUrl,
              listingId: listing.id,
            });

          /**
           * Set the ordering on the photo.
           */
          photoInstance.assignAttributes({
            orderIndex: index,
          });

          return photoInstance;
        },
      );

      /**
       * Find any listing photos that are on the listing but are not included
       * in the form value.
       */
      const listingPhotosToDestroy = listing.listingPhotos.filter(
        ({photo}) => !photosFormValue.includes(photo),
      );

      /**
       * Mark any remaining photos on the listing to be destroyed.
       */
      listingPhotos = [
        ...listingPhotos,
        ...listingPhotosToDestroy.map((listingPhoto) => {
          listingPhoto.isMarkedForDestruction = true;
          return listingPhoto;
        }),
      ];

      /**
       * Set the listing photo instances on the listing.
       */
      listing.listingPhotos = listingPhotos;

      /**
       * Save the changes to the listing.
       */
      const savedListing = await listing.save({with: 'listingPhotos'});

      if (!savedListing) {
        // TODO: Handle fail to save;
        setSubmitting(false);
        return;
      }

      /**
       * Set changes on the property.
       */
      property.lastOnboardingStepCompleted = 'listing_photos';

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.ListProperty_CompleteUploadPhotosStep,
        {
          propertyId: property.id,
          listingId: listing.id,
        },
      );

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      setSubmitting(false);

      actions.setSubmitting(false);
    },
    [listing, property, queryClient],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        photos: listing.listingPhotos
          .sort((photoA, photoB) => photoA.orderIndex - photoB.orderIndex)
          .map(({photo}) => photo),
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        photos: Yup.array().min(4, 'Please add at least 4 photos'),
      }),
    }),
    [handleSubmit, listing?.listingPhotos],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  const onChange = useCallback(
    (files: string[]) => {
      form.setFieldValue('photos', files);
    },
    [form],
  );

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting || uploadingPhotos,
      },
    },
  });

  /**
   * Create the storage handler for uploading listing photos
   * to provide to the file uploader.
   */
  const listingPhotosStorageHandler = useMemo(
    () => createListingPhotosStorageHandler({listing, currentUser}),
    [listing, currentUser],
  );

  return (
    <StepContainer
      fullWidth
      align="center"
      title="Add photos..."
      subtitle="The first photo will be your display photo. Drag and drop photos to rearrange.">
      <div className="flex w-full">
        <FileUploader
          mode="multiple"
          accept={{
            'image/png': [],
            'image/jpeg': [],
            'image/jpg': [],
          }}
          maxFileSize="8MB"
          files={form.values.photos}
          onChange={onChange}
          onActive={() => setUploadingPhotos(true)}
          onIdle={() => setUploadingPhotos(false)}
          storageHandler={listingPhotosStorageHandler}
          firstFileIsMain
        />
      </div>
      <InlineError error={form.errors.photos} />
    </StepContainer>
  );
};

export default PhotosStep;
